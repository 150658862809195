import React from "react";
import { graphql } from "gatsby"


class Message extends React.Component {

  render() {
    return (
      <div className="messageHookSv"></div>
    );
  }
  
}

export default Message

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`